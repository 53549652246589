
import React from 'react';
import Login from './components/Login';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from './components/Dashboard';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import {Alert, Snackbar } from "@mui/material";
import { setSnackBar } from "./store/Slice";





const App = ({ msalInstance }) => {


  const snackBar = useSelector((state) => state.Slice.snackBar);

  const dispatch = useDispatch();
  

  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const payload = {
      snackBar: { open: false,
                  state: snackBar.state,
                  msg: snackBar.msg
      }
    }
    dispatch(setSnackBar(payload))
  };



  

  return (
    <>
    

    <MsalProvider instance={msalInstance}>

      <AuthenticatedTemplate>
      
        <Dashboard></Dashboard>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login></Login>
      </UnauthenticatedTemplate>

    </MsalProvider>
    <Snackbar open={snackBar.open} autoHideDuration={6000} onClose={handleClose}
       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBar.state}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBar.msg}
        </Alert>
      </Snackbar>
    
    </>

  )
};


export default App;
