import React, { useEffect, useState } from 'react'
import {  useDispatch } from 'react-redux';
import { Typography, Button } from '@mui/material';
import { setSnackBar } from '../../../store/Slice';
import { getReports, addRptGroup  } from '../../../services/pbiCracksApi';
import Tags from '../../../shared/AutoCompleteChipsReport';

const GroupAddRp = (props) => {
    const dispatch = useDispatch();
    const [tagsValue, setTagsValue] = useState([]);
    const [reports, setReports] = useState([]);

  
    const handleTagsChange = newValue => {
      
      setTagsValue(newValue);
    };
  
  
    useEffect(() => {
      const fetchReports = async () => {
        try {
          const fetchReports = await getReports();
          setReports(fetchReports);
          

        } catch (error) {
          
        }
      };
      fetchReports(); 
    }, []);

   

    const handleAddReports = async () => {
        if(validate()){
        try {

            await addRptGroup(tagsValue, props.id)
            props.setUpdate(true);
            changeSnackbar('success', 'Agregado correctamente')
            props.handleCloseModal()


        } catch (error) {
          changeSnackbar('error', 'Error al agregar reportes')
        }}

    }

    const changeSnackbar = (estado, mensaje) => {

      const payload = {
        snackBar: {
          open: true,
          state: estado,
          msg: mensaje
        }
      }
  
      dispatch(setSnackBar(payload));
    };

    const validate = () => {

      if(Object.keys(tagsValue).length === 0){
        changeSnackbar('error', 'Seleccione reportes para agregar')
        return false
      }

        return true

    }

    

    return (
      <>
     <Typography sx={{ fontSize: 'xx-Large', fontWeight: '600', color: '#f98727', width: '100%', textAlign: 'center' }}>Agregar reportes</Typography>
      
      <Tags value={tagsValue}  onChange={handleTagsChange} reports={reports} /> 
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        padding: '1vw'
      }}>

      <Button sx={{ marginTop: '3vh'}} variant="contained" onClick={handleAddReports}>Agregar</Button>
      </div>
    </>
    );
}

export default GroupAddRp
