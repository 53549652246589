import React  from 'react';
import LULogo from '../assets/LULogo.png'
import { useDispatch, useSelector } from 'react-redux';
import { setlateralMenuOpen, setLogoutUser, setSnackBar, setError } from '../store/Slice';
import { useMsal } from '@azure/msal-react';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AvatarMenu from './AvatarMenu';





const Header = () => {
  const lateralMenuOpen = useSelector((state) => state.Slice.lateralMenuOpen);
  const errorApp = useSelector((state) => state.Slice.error);
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  axios.interceptors.response.use(function (response) {
   
    
    return response;
  }, function async (error)  {
    

    if(error.response.status === 401){

     
      if(!errorApp){
        const payload = {
          error: true,
        }

        dispatch(setError(payload))
          ErrorControl()       
      }
      
    }
  return false;
  });

   const changeSnackbar = (estado, mensaje) => {

    const payload = {
      snackBar: {
        open: true,
        state: estado,
        msg: mensaje
      }
    }
    
    dispatch(setSnackBar(payload));
  };

  const ErrorControl = async () => {

    
   
    await changeSnackbar('error', 'Token expirado');
   
     localStorage.clear();
      dispatch(setLogoutUser());
     instance.setActiveAccount([])
     window.location.reload()
     navigate(`/`)
      
  
  }

  

  const lateralMenuAction =() =>{
    
    if(lateralMenuOpen){

      const payload = {
        lateralMenuOpen: false
      };
      dispatch(setlateralMenuOpen(payload));
    }
    else{
      const payload = {
        lateralMenuOpen: true
      };
      dispatch(setlateralMenuOpen(payload));
    }

  }

  return (
    <>
      <div className='div-header'>
      <div style={{
          width: '100%',
          display: 'flex',
          margin: 'auto',
          alignItems: 'center'
        }}>
        <MenuIcon className='header-menu' onClick={()=>lateralMenuAction()}/>
        <img alt='' src={LULogo} style={{
          height: '3rem'
        }} />
        </div>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          margin: 'auto',
          paddingRight: '1rem'
        }}>
          
          <AvatarMenu  >
          </AvatarMenu>
        </div>
      </div>
    </>
  );
}

export default Header;