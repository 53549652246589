import axios from 'axios';
import { BASE_URL } from '../utils/constants';


const getHeaders = () => {
  const token = localStorage.getItem('accessToken')
  return {
    headers: {
      Authorization: token,
    },
  };

};



const createReport = async (name, reportId, groupId, description) => {

  const res = await axios.post(
    `${BASE_URL}/reports`,
    { name, reportId, groupId, description },
    getHeaders()
  );


  return res.data;
}
///:id/users
const addUsrGroup = async (users, id) => {

   await axios.patch(
    `${BASE_URL}/groups/${id}/users`,
    { users },
    getHeaders()
  );
};

const addRptGroup = async (reports, id) => {

   await axios.patch(
    `${BASE_URL}/groups/${id}/reports`,
    { reports },
    getHeaders()
  );
};

const getGroup = async (groupId) => {
  try {
    const res = await axios.get(`${BASE_URL}/groups/${groupId}`,
      getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
}

const getGroups = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/groups`,
      getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
}

const postReport = async (url, name, reportId) => {
  const res = await axios.post(
    `${BASE_URL}/report`,
    { url, name, reportId },
    getHeaders());


  return res.data;

}

const postUser = async (mail, name) => {
  try {

    const res = await axios.post(
      `${BASE_URL}/users`,
      { mail, name },
      getHeaders()
    );
    localStorage.setItem('user', JSON.stringify(res.data));
    return res.data;
  } catch (error) {
    throw error;
  }
};


const getReport = async (reportId) => {
  try {
    const res = await axios.get(`${BASE_URL}/reports/${reportId}`, getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
}


const getUsers = async (token) => {
  try {

    const res = await axios.get(`${BASE_URL}/users`, getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
};

const getUser = async (token) => {
  try {

    const res = await axios.get(`${BASE_URL}/user`, getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
};

const getReports = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/reports`, getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
};
const getRecentReports = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/reports/recents`, getHeaders());
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

const createGroup = async (name, users) => {
  try {
    const res = await axios.post(`${BASE_URL}/groups`, { name, users }, getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
};

const grantSuperUser = async (mail) => {
  return await axios.post(`${BASE_URL}/users/${mail}/grant-superuser`, {}, getHeaders());
}

const getUserId = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/users/${id}`, getHeaders());
    
    return res.data;
  } catch (error) {
    throw error;
  }
};

const getUserMe = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/users/me`, getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
};

const postFavorite = async (idReport, favorite) => {
  try {
    const res = await axios.post(`${BASE_URL}/reports/${idReport}/favorite`, { favorite }, getHeaders());
    return res.data;
  } catch (error) {
    throw error;
  }
};

const addReportUser = async (mail,reports) =>{
  
    const res = await axios.post(`${BASE_URL}/users/${mail}/add-report`, { reports }, getHeaders());
    
    return res.data;
  
};

const deleteGroup = async (id) => {

  const res = await axios.delete(`${BASE_URL}/groups/${id}`, getHeaders());
  console.log(res)
  return await res.data;
}

const removeReportGroup = async (id, reports) => {

  const res = await axios.patch(`${BASE_URL}/groups/${id}/remove-reports`, {reports},getHeaders());
  
  return res.data;
}

const removeUsersGroup = async (id, users) => {
  const res = await axios.patch(`${BASE_URL}/groups/${id}/remove-users`, {users},getHeaders());
  
  return res.data;
}

const grantAdminGroup = async (id, mail, admin) => {

  const res = await axios.post(`${BASE_URL}/groups/${id}/grant-admin`, {mail, admin},getHeaders());
  
  return res.data;
}

const removeUserReport = async (mail, reports) => {

  const res = await axios.patch(`${BASE_URL}/users/${mail}/remove-report`, {reports},getHeaders());
  
  return res.data;
}

const deleteReport = async (id) => {

  const res = await axios.delete(`${BASE_URL}/reports/${id}`, getHeaders());
  
  return res.data;
}

export {
  deleteReport,
  removeUserReport,
  grantAdminGroup,
  removeUsersGroup,
  removeReportGroup,
  deleteGroup,
  addReportUser,
  postFavorite,
  getUserMe,
  getUserId,
  postUser,
  getUsers,
  createGroup,
  postReport,
  getReport,
  createReport,
  getReports,
  getGroup,
  getGroups,
  addUsrGroup,
  addRptGroup,
  grantSuperUser,
  getUser,
  getRecentReports
};
