import React, { useState } from "react";
import GroupForm from "./components/GroupForm";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { getGroups } from "../../services/pbiCracksApi";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./Groups.css";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../components/AlertDialog";

const Groups = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [groups, setGroups] = useState("");
  const [update, setUpdate] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const obtenerGroups = async () => {
    try {
      const resp = await getGroups();

     

      setGroups(resp);

    } catch (error) {
      
    }
  };
  if (groups === "") {
    obtenerGroups();
  }

  if(update === true){
    obtenerGroups();
    setUpdate(false);
  }

  const editar =  (params) => {
    try {
      navigate(`/grupos/${params.ID}`);
      
    } catch (error) {
      
    }
  };

  const columns = [
    {
      field: "Name",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "editar",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => (
        
            <RemoveRedEyeOutlinedIcon className={'action-icon'}onClick={() => editar(row)}/>
        
      ),
      sortable: false,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "eliminar",
      headerName: "",
      type: "actions",
      renderCell: ({row}) => (
        <AlertDialog disabled={!(row.readonly === 0 || user.Role === 'superuser')} setUpdate={setUpdate} option='delete-group' ID={row.ID}/>
      ),
      sortable: false,
      maxWidth: 100,
      flex: 1,
    },
  ];

  return (
    <>
      <div>
        <div className="groups-header">
          <Typography
            sx={{ fontSize: "xxx-Large", fontWeight: "600", width: "100%" }}
            color="white"
          >
            Grupos
          </Typography>
          <div style={{ margin: "auto" }}>
            <IconButton onClick={handleOpenModal}>
              <AddCircleOutlineIcon
                sx={{ fontSize: 40 }}
                style={{ color: "white" }}
              />
            </IconButton>
          </div>
        </div>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              p: 4,
            }}
          >
            <GroupForm setUpdate={setUpdate} handleCloseModal={handleCloseModal} />
          </Box>
        </Modal>

        <div
          style={{
            minHeight: "79vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "93vw" }}>
            {!groups ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  height: "10vh",
                }}
              >
                <CircularProgress size="6vw" />
              </Box>
            ) : groups.length > 0 ? (
              <DataGrid
                getRowId={(row) => row.ID}
                rows={groups}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 15, 20]}
                
                onCellEditCommit={(params) => editar(params)}
              />
            ) : <p>No existen grupos.</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Groups;
