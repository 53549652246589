import React from 'react';
import {  useDispatch } from 'react-redux';
import { Button,  Typography } from '@mui/material';
import { grantAdminGroup } from '../../../services/pbiCracksApi';
import { setSnackBar } from '../../../store/Slice';

const GroupAdminModal = ({ handleCloseModal, adminData, id}) => {
    const dispatch = useDispatch();
    
  const handleSubmit = async () => {
    try {
        if(adminData.readonly === 1){
            if(await grantAdminGroup( id ,adminData.Mail,1) === 'Created'){

                changeSnackbar('success', `${adminData.Name} es administrador!`)
            }
            else{
                changeSnackbar('error', 'Error al modificar permiso')
            }
        }else{
            if(await grantAdminGroup(id,  adminData.Mail,0)=== 'Created'){
            changeSnackbar('success', `${adminData.Name} ya no es administrador`)
        }
        else{
            changeSnackbar('error', 'Error al modificar permiso')
        }
        }
        handleCloseModal();
      
    } catch (error) {
      changeSnackbar('error', 'Error al modificar permiso')
    }
  }

  const changeSnackbar = (estado, mensaje) => {

    const payload = {
      snackBar: {
        open: true,
        state: estado,
        msg: mensaje
      }
    }

    dispatch(setSnackBar(payload));
  };


  return (
    <>
    <div style={{display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'}}>

    
     <Typography sx={{ fontSize: 'xx-Large', fontWeight: '600', color: '#f98727', width: '100%', textAlign: 'center' }}>Estas seguro?</Typography>
     { adminData.readonly === 1?
         <p>Al aceptar {adminData.Name} será administrador!</p>
        :
        <p>Al aceptar {adminData.Name} se le quitara el permiso de administrador</p>
     }
     <div style={{width: '100%',
    display: 'flex',
    justifyContent: 'space-around'}}>

      <Button sx={{  marginTop: '3vh',     backgroundColor: '#777777', width: '8vw'}} variant="contained"  onClick={handleCloseModal}>Cancelar</Button>
      <Button sx={{  marginTop: '3vh',  width: '8vw'}} variant="contained" onClick={handleSubmit}>Aceptar</Button>
    </div>
    </div>
    </>
  );
};

export default GroupAdminModal;

/*
*/
 