import React, { useEffect, useState } from 'react';
import {  useDispatch } from 'react-redux';
import { Button,  Typography } from '@mui/material';
import Tags from '../../../shared/AutoCompleteChips';
import {  setSnackBar } from '../../../store/Slice';
import { getUsers, addUsrGroup } from '../../../services/pbiCracksApi';

const GroupAddUsr = (props) => {
  const [tagsValue, setTagsValue] = useState([]);
  const [users, setUsers] = useState([]);
  
  const dispatch = useDispatch();
  const handleTagsChange = newValue => {
    setTagsValue(newValue);
  };

  

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await getUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        
      }
    };
    fetchUsers(); 
  }, []);

  const handleAddUsrs = async () => {
    if(validate()){

      try {
        
        await addUsrGroup(tagsValue, props.id)
        props.setUpdate(true);
        changeSnackbar('success', 'Agregado correctamente')
        props.handleCloseModal()
        
      } catch (error) {
        changeSnackbar('error', 'Error al agregar usuarios')
        
      }
    }
  };

  const changeSnackbar = (estado, mensaje) => {

    const payload = {
      snackBar: {
        open: true,
        state: estado,
        msg: mensaje
      }
    }

    dispatch(setSnackBar(payload));
  };

  const validate = () => {

    if(Object.keys(tagsValue ).length === 0){
      changeSnackbar('error', 'Seleccione usuarios para agregar')
      return false
    }

      return true

  }


  return (
    <>
     <Typography sx={{ fontSize: 'xx-Large', fontWeight: '600', color: '#f98727', width: '100%', textAlign: 'center' }}>Agregar usuarios</Typography>
      
      <Tags value={tagsValue} onChange={handleTagsChange} users={users} /> 
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        padding: '1vw'
      }}>

      <Button sx={{ marginTop: '3vh'}} variant="contained" onClick={handleAddUsrs}>Agregar</Button>
      </div>
    </>
  );
};

export default GroupAddUsr;


 