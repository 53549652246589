import React, { useEffect } from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { useDispatch} from 'react-redux';
import { setLogoutUser } from '../store/Slice';


export default function AvatarMenu() {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [Nombre, setNombre] = React.useState(user?.Name);
  const [Mail, setMail] = React.useState(user?.Mail);
  const [Role, setRole] = React.useState(user?.Role);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  useEffect(() => {
    

      setNombre(user?.Name)
      setMail(user?.Mail)
      setRole(user?.Role)
      
    

    
  }, [ user]);

  const options = [
    {field: 'Nombre', label: Nombre, onCLick: 0, disable: 1},
    {field: 'Mail',label:   Mail, onCLick: 0, disable: 1},
    {field: 'Role',label:   Role, onCLick: 0, disable: 1},
    {field: 'Perfil',label:'Perfil', onCLick: 1, disable: 0},
    {field: 'Logout',label:'Logout', onCLick: 2, disable: 0},
  ];

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    if(index === 1){
      navigate(`/perfil`)
    }
    if(index === 2){
      logout()
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    
    instance.logoutPopup();
    dispatch(setLogoutUser());
    navigate(`/`)
   }

   const bold = (option) => {
    if(option.field === 'Nombre'){
      return <Typography sx={{padding: 1, paddingLeft: '1vw', paddingRight: '1vw', fontWeight: 'bold'}}> {option.label}</Typography>
    }
    else{
      return <Typography sx={{padding: 1, paddingLeft: '1vw', paddingRight: '1vw'}}> {option.label}</Typography>
    }

   }

  return (
    <div>
      <List
      sx={{padding: 0}}
        component="nav"
        aria-label="Device settings"
      >
        <ListItemButton
        sx={{padding: 0, backgroundColor: 'transparent'}}
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <Avatar  >
            <PersonIcon />
          </Avatar>
        </ListItemButton>
      </List>
      <Menu
        
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <>
          {(option.disable === 1) ? 
          

            bold(option)
            
            :
          <>
          <hr style={{width: '88%'}}></hr>
          <MenuItem
          key={option}
          disabled={option.disable === 1}
          selected={index === selectedIndex}
          onClick={(event) => handleMenuItemClick(event, option.onCLick)}
          >
            {option.label}
          </MenuItem>
            </>}
            </>
        ))}
      </Menu>
    </div>
  );
}