import React from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export default function Tags(props) {


  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        multiple
        id="tags-standard"
        freeSolo
        filterSelectedOptions
        options={props.users || []}
        onChange={(e, newValue) => props.onChange(newValue)}
        getOptionLabel={option => option.Mail}
        value={props.value}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Usuarios"
            margin="normal"
            fullWidth
            size="small"
          />
        )}
      />
     
    </div>
  );
}

/**
 * linea 45   <div classmail="selectedTags" >{valHtml}</div>
 */
