
import React from 'react';
import {  useDispatch } from 'react-redux';
import { Typography, TextField, Button, Grid } from '@mui/material';
import { setSnackBar } from '../../../store/Slice';
import { createReport } from '../../../services/pbiCracksApi';





const AddReport = ({handleCloseModal}) => {

  const grs = [{ idGrupo: 1, Nombre: 'Mapfre' }, { idGrupo: 2, Nombre: 'Handy' }, { idGrupo: 3, Nombre: 'Itau' }]
  const dispatch = useDispatch();

  const [grupos, setGrupos] = React.useState([]);
  const [nombre, setNombre] = React.useState('');
  const [error, setError] = React.useState({});
  const [idReporte, setIdReprote] = React.useState('');
  const [idWorkspace, setIdWorkspace] = React.useState('');
  const [descripcion, setDescripcion] = React.useState('');




  const addReport = async () => {
    if (validar()) {

      try {

        await createReport(nombre, idReporte, idWorkspace, descripcion);

        changeSnackbar('success', 'Se agrego correctamente')
        handleCloseModal()


      } catch (error) {

        changeSnackbar('error', 'Error al agregar')
       
      }
    }

  }
  if (grupos[0] == null) {
    setGrupos(grs);
  }



  const changeSnackbar = (estado, mensaje) => {

    const payload = {
      snackBar: {
        open: true,
        state: estado,
        msg: mensaje
      }
    }

    dispatch(setSnackBar(payload));
  };

  const validar = () => {

    const e = {}

    if (nombre === '') {
      e.nombre = true
    }
    if (idReporte === '') {
      e.idReporte = true

    }
    if (idWorkspace === '') {
      e.idWorkspace = true

    }
    if (descripcion === '') {
      e.descripcion = true
    }

    if (Object.keys(e).length > 0) {
      setError(e);
      changeSnackbar('error', 'Complete los campos obligatorios')
      return false
    }
    setError(e);
    return true

  }


  return (
    <>





      <div className='div-container-add-report'>
        <Typography sx={{ fontSize: 'xx-Large', fontWeight: '600', color: '#f98727', width: '100%', textAlign: 'center' }}>Agregar reporte</Typography>

        <Grid sx={{ width: '35%' }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>

            <TextField sx={{ width: '100%' }} id="standard-basic" error={error.nombre} label="Nombre" variant="standard" value={nombre} onChange={(event) => setNombre(event.target.value)} />

          </Grid>
          <Grid item xs={6}>

            <TextField sx={{ width: '100%' }} id="standard-basic" error={error.idReporte} label="Id reporte" variant="standard" value={idReporte} onChange={(event) => setIdReprote(event.target.value)} />

          </Grid>
          <Grid item xs={6}>

            <TextField sx={{ width: '100%' }} id="standard-basic" error={error.idWorkspace} label="Id workspace" variant="standard" value={idWorkspace} onChange={(event) => setIdWorkspace(event.target.value)} />

          </Grid>
          <Grid item xs={12}>

            <TextField multiline
              rows={2}
              sx={{ width: '100%' }} id="standard-basic" error={error.descripcion} label="Descripcion" variant="standard" value={descripcion} onChange={(event) => setDescripcion(event.target.value)} />

          </Grid>

        </Grid>


        <div className='div-add-report-button'>
          <Button sx={{ widows: '10vw', width: '30%' }} variant="contained" onClick={() => addReport()} >Agregar</Button>
        </div>
      </div>

    </>
  );

}

//<Button sx={{  widows: '10vw',  width: '30%' }} variant="contained" color='secondary' onClick={()=> cancelAdding()}>Cancelar</Button>
export default AddReport;