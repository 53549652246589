import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { setSnackBar } from '../store/Slice';
import { deleteGroup, deleteReport, removeReportGroup, removeUserReport, removeUsersGroup } from '../services/pbiCracksApi';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setDataDialog = () => {
    const option = props.option;

    if (option === 'delete-report') {
      setData({
        title: '¿Eliminar reporte?',
        body: 'Si acepta el reporte quedara eliminado de forma permanente.',
        msgExito: 'Se elimino el reporte correctamente',
        msgError: 'No se pudo eliminar reporte'
      })
    }
    if (option === 'delete-group') {
      setData({
        title: '¿Eliminar grupo?',
        body: 'Si acepta el grupo quedara eliminado de forma permanente.',
        msgExito: 'Se elimino el grupo correctamente',
        msgError: 'No se pudo eliminar el grupo'
      })
    }
    if (option === 'remove-user-report') {
      setData({
        title: '¿Quitar reporte?',
        body: 'Si acepta el reporte quedara desvinculado del usuario.',
        msgExito: 'Se quito el reporte correctamente',
        msgError: 'No se pudo quitar reporte'
      })

    }  
    if (option === 'remove-user-group') {
      setData({
        title: '¿Quitar grupo?',
        body: 'Si acepta el grupo quedara desvinculado del usuario.',
        msgExito: 'Se quito el grupo correctamente',
        msgError: 'No se pudo quitar grupo'
      })

    }
    if (option === 'remove-group-user') {
      setData({
        title: '¿Quitar usuario?',
        body: 'Si acepta el usuario quedara desvinculado del grupo.',
        msgExito: 'Se quito el usuario correctamente',
        msgError: 'No se pudo quitar el usuario'
      })

    }
    if (option === 'remove-group-report') {
      setData({
        title: '¿Quitar reporte?',
        body: 'Si acepta el reporte quedara desvinculado del grupo.',
        msgExito: 'Se quito el reporte correctamente',
        msgError: 'No se pudo quitar el reporte'
      })

    }

  }

  if (!data) {
    setDataDialog()
  }

  const handlerDelete = async () => {
    const option = props.option;
    let status;

    if (option === 'delete-report') {

      status = await deleteReport(props.ReportID)


    }
    if (option === 'delete-group') {

      status = await deleteGroup(props.ID)

    }
    if(option === 'remove-user-report'){

      status = await removeUserReport(props.Mail, [props.reports])
      
    }
    if(option === 'remove-user-group'){


      status = await removeUsersGroup(props.groups.ID, [{Mail: props.Mail}])


    }
    if(option === 'remove-group-user'){


      status = await removeUsersGroup(props.group.ID, [{Mail: props.user.Mail}])
    }
    if(option === 'remove-group-report'){

      status = await removeReportGroup(props.group.ID, [props.reports])
    }


    if (status === 'OK') {
      changeSnackbar('success', data.msgExito)
      props.setUpdate(true);
      setOpen(false);
    }
    else {
      changeSnackbar('error', data.msgError)
    }
  }

  const changeSnackbar = (estado, mensaje) => {

    const payload = {
      snackBar: {
        open: true,
        state: estado,
        msg: mensaje
      }
    }

    dispatch(setSnackBar(payload));
  };

  return (
    <React.Fragment>
      <IconButton  disabled={props.disabled} onClick={handleClickOpen} sx={{ fontSize: 30 }}>
        <DeleteIcon />
      </IconButton >

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{data?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {data?.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: '#f98727' }} onClick={handleClose}>Cancelar</Button>
          <Button sx={{ color: '#f98727' }} onClick={handlerDelete}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}