import React from "react";
import { setLoginUser, setError } from "../store/Slice";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import FuelBack from "../assets/FuelBack.png";
import FuelLogo from "../assets/fuel2.png";
import { useDispatch } from "react-redux";
import { postUser } from "../services/pbiCracksApi";
import {
  HashRouter, 
  useNavigate,
} from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const { instance } = useMsal();

  const navigate = useNavigate();



  const checkLogged = async () => {
    try {
      const currentAccount = instance.getActiveAccount();

      if (!currentAccount) {

        const apiUser = await postUser(currentAccount.username, currentAccount.name);

        const payload = { ...currentAccount, ...apiUser }
        dispatch(setLoginUser(payload));
      }
    } catch (e) {

    }
  };

  const setLogged = async () => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount != null) {
      dispatch(
        setLoginUser({
          mail: currentAccount.username,
          name: currentAccount.name,
          accessToken: currentAccount.accessToken,
        })
      );
      const apiUser = await postUser(
        currentAccount.username,
        currentAccount.name
      );

      const payload = { ...currentAccount, ...apiUser };


      dispatch(setLoginUser(payload));
    }
  };

  const login = async () => {
    try {
      await instance.loginPopup({
        scopes: ["user.read"],
      });
      const payload = {
        error: false,
      }

      dispatch(setError(payload))

      navigate(`/reportes`)
      setLogged();

    } catch (error) {

    }
  };

  checkLogged();

  return (
    <>
      <div style={{ display: 'flex', height: '80vh', boxShadow: 'rgb(0 0 0 / 50%) 5px 5px 20px 4px' }}>

        <img alt="" src={FuelBack} className="img-login"/>
        <div className="div-log">
          <div style={{ height: '33%', width: '100%', display: 'flex', flexWrap: 'wrap',marginTop: '16vh' }}>

          <img alt="" src={FuelLogo}  style={{width: ' 24.7vw'}}/>
          <div style={{    marginTop: '4rem',
    width: '100%',
    justifyContent: 'center',
    display: 'flex'}}>

          <Button
            sx={{ width: "15rem", margin: 'auto',borderRadius: '50px', color: '#F1862C', border: 'solid #F1862C 2px' }}
            variant="outlined"
            color="primary"
            onClick={() => login()}
            >
            Acceder
          </Button>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Login;
