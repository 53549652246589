import React from 'react';
import Menu from './Menu'
import Header from './Header';
import LULogo2 from '../assets/fuel2.png'


const Dashboard = () => {




    return (
        <>
            <div className='div-container'>
                <Header></Header>
                <Menu></Menu>
                <img alt='' src={LULogo2} style={{
                    position: 'absolute',
                    height: '4vh',
                    top: '95%',
                    right: '1%',
                    opacity: '0.3',
                    filter: 'grayscale(1)'
                }} />
            </div>
        </>
    );

}

export default Dashboard;