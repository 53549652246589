import { createTheme } from '@mui/material/styles';


export const Theme = createTheme({
  palette: {
    primary: {
      main: '#F1862C',
    },
  }, components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
  },
});
