import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from './components/Theme';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { config } from './auth/config';
import Store from './store/Store';
import {
  HashRouter, Router
} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));



const pca = new PublicClientApplication({
  auth: {
    clientId: config.appid,
    authority: config.authority,
    system: {
        allowRedirectInIframe: true
    }
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }

});



pca.addEventCallback( event => {
  //
  if(event.eventType === EventType.LOGIN_SUCCESS){
    
    
    localStorage.setItem('accessToken', event.payload.accessToken)
    
    pca.setActiveAccount(event.payload.account);
  }
})


root.render(
  <>

    <Provider store={Store}>
  
  <HashRouter>
<ThemeProvider theme={Theme}>

    <App msalInstance={pca}/>

</ThemeProvider>

</HashRouter>

</Provider>
    
  </>
);

reportWebVitals();
