import React, { useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getReport } from '../../../services/pbiCracksApi'
import { useParams } from 'react-router-dom';
import { Typography, IconButton, Box} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FullscreenIcon from '@mui/icons-material/Fullscreen';



const Reporte = (props) => {
    const { id } = useParams();
    const [report, setReport] = useState();
    const user = JSON.parse(localStorage.getItem('user'));

    const obtenerReporte = async () => {
      try {
        const resp = await getReport(id);
        setReport(resp);
      } catch (error) {
        
      }
    };

    if (!report) {
      obtenerReporte();
    }

    const fullScreen = () => {
      
    document.getElementById("pruebaFull").requestFullscreen().catch((e)=> console.log(e))
    
    
   
    }
  

    return (
        <>
        <>
        <Box sx={{display: 'flex',
    justifyContent: 'space-between', marginBottom: '1vh', alignItems: 'center'}}> 

        
        <Typography
              sx={{
                fontSize: "xxx-Large",
                fontWeight: "600",
                color: "#f98727",
              }}
            >
              {report?.Name || 'Reporte'}
            </Typography>
            <div >
                    <IconButton onClick={fullScreen} sx={{marginRight: '-11px', padding: '0px'}}>
                        <FullscreenIcon sx={{ fontSize: 40 }}  />
                    </IconButton>
                </div>
                </Box>
                <Typography sx={{fontSize: 'x-large', marginBottom: '4vh'}}>
              {report?.description}
            </Typography>
            </>
            
            { report ? <div id="pruebaFull" style={{height: '65vh'}}>  <PowerBIEmbed 
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: report.ReportID,
                    embedUrl: report.Url,
                    accessToken: report.token,
                    tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                    },
                    filters: [{
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "Funcionarios",
                        column: "Mail_Level_Up"
                      },
                      operator: "In",
                      values: [user.Mail]
                    },
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "AIR_Funcionarios",
                        column: "Mail_Level_Up"
                      },
                      operator: "In",
                      values: [user.Mail]
                    }],
                }}
                eventHandlers={
                    new Map([
                        ['loaded', function () {  }],
                        ['rendered', function () {  }],
                        ['error', function (event) {  }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }
                cssClassName={"embed-container"}
                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            /> </div> 
           
            :                 <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
            }
            </>
            
    );

}

export default Reporte;