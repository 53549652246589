import React, { useState, useEffect } from "react";
import {
  Typography,
  IconButton,
  Box,
  Modal,
  Autocomplete,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./Reportes.css";
import AddReport from "./components/AddReport";
import { getReports, getRecentReports } from "../../services/pbiCracksApi";
import ReportCard from "./components/ReportCard";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";

const Reportes = () => {
  const [reports, setReports] = useState();
  const [recientes, setRecientes] = useState();
  const [reportsAux, setReportsAux] = useState();
  const [openModalRpt, setOpenModalRpt] = useState(false);
  const navigate = useNavigate();
  const locationURL = useLocation();
  const [update, setUpdate] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!reports) {
      obtenerReportes();
    }

  

    if (!recientes) {
      obtenerReportesRecientes();
    }

    if(update === true){
      obtenerReportes();
      obtenerReportesRecientes();
      setUpdate(false);
    }
    /* else{
       setReports(reportsAux)
     }*/
  }, [reportsAux, reports, update, recientes]);

  if (locationURL.pathname !== "/reportes") {
    navigate(`/reportes`);
  }

  const inputAutoChange = (value) => {
    const aux = reports.filter((r) =>
      r.Name.toLowerCase().includes(value.toLowerCase())
    );

    setReportsAux(aux);
  };

  const obtenerReportes = async () => {
    try {
      const resp = await getReports();
      setReports(resp);
      setReportsAux(resp);
    } catch (error) {}
  };
  const obtenerReportesRecientes = async () => {
    const resp = await getRecentReports();
    setRecientes(resp);
  };

  const handleOpenModalRpt = () => {
    setOpenModalRpt(true);
  };

  const handleCloseModal = async () => {
    try {
      await obtenerReportes();
    } catch (err) {
      console.error(err);
    }
    setOpenModalRpt(false);
  };

  return (
    <>
      <Modal open={openModalRpt} onClose={() => handleCloseModal()}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
          }}
        >
          <AddReport handleCloseModal={handleCloseModal}></AddReport>
        </Box>
      </Modal>

      <div className="reportes-header">
        <Typography
          sx={{ fontSize: "xxx-Large", fontWeight: "600", width: "100%" }}
          color="white"
        >
          Reportes
        </Typography>
        <div style={{ margin: "auto" }}>
          <IconButton
            disabled={!(user?.Role === "superuser")}
            onClick={() => handleOpenModalRpt()}
          >
            <AddCircleOutlineIcon
              sx={{ fontSize: 40 }}
              style={{ color: "white" }}
            />
          </IconButton>
        </div>
      </div>

      <div style={{ width: "100%", minHeight: "79vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2vh",
          }}
        >
          <Typography
            sx={{
              fontSize: "xx-Large",
              fontWeight: "600",
              color: "#f98727",
            }}
          >
            Favoritos
          </Typography>
        </div>
        <hr className="reports-hr"></hr>
        <div className="reports-reportes-container">
          {!reports ? (
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              <CircularProgress size="4vw" sx={{ margin: "auto" }} />
            </Box>
          ) : reports?.filter((r) => r.favorite === 1).length > 0 ? (
            reports
              ?.filter((r) => r.favorite === 1)
              .map((report, index) => {
                return <ReportCard setUpdate={setUpdate} key={index} report={report}></ReportCard>;
              })
          ) : (
            <div>No hay reportes favoritos</div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2vh",
          }}
        >
          <Typography
            sx={{
              fontSize: "xx-Large",
              fontWeight: "600",
              color: "#f98727",
            }}
          >
            Recientes
          </Typography>
        </div>
        <hr className="reports-hr"></hr>
        <div className="reports-reportes-container">
          {!recientes ? (
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              <CircularProgress size="4vw" sx={{ margin: "auto" }} />
            </Box>
          ) : recientes.length > 0 ? (
            recientes?.map((report, index) => {
                return <ReportCard setUpdate={setUpdate} key={index} report={report}></ReportCard>;
              })
          ) : (
            <div>No hay reportes recientes</div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2vh",
          }}
        >
          <Typography
            sx={{
              fontSize: "xx-Large",
              fontWeight: "600",
              color: "#f98727",
            }}
          >
            Todos
          </Typography>
          <Autocomplete
            sx={{ width: "10vw" }}
            id="free-solo-2-demo"
            disableClearable
            freeSolo
            options={reports?.map((option) => option.Name)}
            noOptionsText={"No  coincide con ningun reporte"}
            onInputChange={(e, f) => inputAutoChange(f)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Buscar"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              );
            }}
          />
        </div>
        <hr className="reports-hr"></hr>
        <div className="reports-reportes-container">
          {!reportsAux ? (
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              <CircularProgress size="4vw" sx={{ margin: "auto" }} />
            </Box>
          ) : reportsAux?.length > 0 ? (
            reportsAux?.map((report, index) => {
              return <ReportCard setUpdate={setUpdate} key={index} report={report}></ReportCard>;
            })
          ) : (
            <div>No coincide con ningun reporte</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Reportes;
