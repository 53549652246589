import React, { useState } from "react";
import { Typography, IconButton, Box, Modal } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "../Groups.css";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import GroupAddUsr from "./GroupAddUsr";
import GroupAddRp from "./GroupAddRp";
import { getGroup } from "../../../services/pbiCracksApi";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../../../components/AlertDialog";
import GroupAdminModal from "./GroupAdminModal";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from "react-router-dom";

const Group = (props) => {
  const  {id}  = useParams();
  const navigate = useNavigate();
  const [openModalUsr, setOpenModalUsr] = useState(false);
  const [openModalRpt, setOpenModalRpt] = useState(false);
  const [openModalAdmin, setOpenModalAdmin] = useState(false);
  const [group, setGroup] = useState();
  const [update, setUpdate] = useState(false);
  const [adminData, setAdminData] = useState();
  const user = JSON.parse(localStorage.getItem("user"));

  const obtenerGrupo = async () => {
    try {
      const resp = await getGroup(id);
      setGroup(resp);
    } catch (error) {
      
    }

  };

  
  
    if(update === true){
      obtenerGrupo();

      setUpdate(false);
    }
    

  if (!group) {
    obtenerGrupo();
  }
  const handleOpenModalUsr = () => {
    setOpenModalUsr(true);
  };
  const handleOpenModaAdmin = (row) => {
    setAdminData(row)
    setOpenModalAdmin(true);

  };
  const verReport = (id) =>{
    navigate(`/reportes/${id}`)
  }

 

  const handleOpenModalRpt = () => {
    setOpenModalRpt(true);
  };

  const handleCloseModal = async () => {
    setUpdate(true);
    setOpenModalUsr(false);
    setOpenModalRpt(false);
    setOpenModalAdmin(false);
  };

  const columnsReports = [
    {
      field: "ReportID",
      headerName: "ID",
     
      flex: 3,
    },
    {
      field: "Name",
      headerName: "Nombre",
      
      flex: 4
    },
    {
      field: 'action',
      type: 'actions',
      getActions: (params) => [
       
      ],
      flex: 2
      
    }
    ,
    {
      field: "editar",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => (
        
          <RemoveRedEyeOutlinedIcon   className={'action-icon'}onClick={() => verReport(row.ReportID)}/>
        
      ),
      sortable: false,
      flex: 2,
    },

    {
      field: "eliminar",
      headerName: "Eliminar",
      type: "actions",
      renderCell: ({row}) => (
        <AlertDialog disabled={(group.readonly === 1)} setUpdate={setUpdate} option='remove-group-report' group={group} reports={row}/>
      ),
      sortable: false,
      flex: 2
      
    }
    
  ];

  const columnsUsers = [
    {
      field: "Name",
      headerName: "Nombre",
      
      flex: 3,
    },
    {
      field: "Mail",
      headerName: "Email",
      
      flex: 4,
    },
    
    {
      field: "administrador",
      headerName: "Administrador",
      type: "actions",
      renderCell: ({row}) => (
        <>{ row.readonly === 1 ?

          <CancelOutlinedIcon />
        :
        <CheckCircleOutlineIcon sx={{color: 'green'}} />
        }
          </>
      ),
      sortable: false,
      flex: 2
    },
    
    
    {
      field: 'actions',
      type: 'actions',
      getActions: ({row}) => [
        
        <GridActionsCellItem
        label={row.readonly === 0?"Quitar permisos administrador": "Dar permisos administrador" }
         onClick={() =>handleOpenModaAdmin(row)}
        showInMenu
        />
      
      
        
      ],
      flex: 2
    },
    {
      field: "eliminar",
      headerName: "Eliminar",
      type: "actions",
      renderCell: ({row}) => (
        <AlertDialog disabled={(group.readonly === 1)} setUpdate={setUpdate} option='remove-group-user' group={group} user={row}/>
      ),
      sortable: false,
      flex: 2
    }
  ];

  return (
    <>
      <Modal open={openModalRpt} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <GroupAddRp setUpdate={setUpdate} id={id} handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>

      <Modal open={openModalUsr} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <GroupAddUsr setUpdate={setUpdate} id={id} handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>

      <Modal open={openModalAdmin} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <GroupAdminModal setUpdate={setUpdate} id={id} adminData={adminData} handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>

      {group ? (
        <div style={{maxWidth: '93vw'}}>
          <Typography
            sx={{
              fontSize: "xxx-Large",
              fontWeight: "600",
              color: "black",
              width: "100%",
              textAlign: "center",
            }}
          >
            {group?.Name}
          </Typography>

          <div className="group-header">
            <Typography
              sx={{
                fontSize: "xx-Large",
                fontWeight: "600",
                color: "#f98727",
                width: "100%",
              }}
            >
              Usuarios
            </Typography>
            <div style={{ margin: "auto" }}>
              <IconButton disabled={!(group?.readonly === 0 || user.Role === 'superuser')} onClick={() => handleOpenModalUsr()}>
                <AddCircleOutlineIcon
                  sx={{ fontSize: 40 }}
                  style={{ color: "#f98727" }}
                />
              </IconButton>
            </div>
          </div>

          <hr className="reports-hr"></hr>
          { (group?.users?.length > 0) ? (
            <DataGrid
            sx={{width: '100%'}}
              getRowId={(row) => row.ID}
              rows={group?.users}
              columns={columnsUsers}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          ) : ( <Typography>
            No hay usuarios asociados 
          </Typography>)}
          <div className="group-header">
            <Typography
              sx={{
                fontSize: "xx-Large",
                fontWeight: "600",
                color: "#f98727",
                width: "100%",
              }}
            >
              Reportes
            </Typography>
            <div style={{ margin: "auto" }}>
              <IconButton  disabled={!(group?.readonly === 0 || user.Role === 'superuser')} onClick={() => handleOpenModalRpt()}>
                <AddCircleOutlineIcon
                  sx={{ fontSize: 40 }}
                  style={{ color: "#f98727" }}
                />
              </IconButton>
            </div>
          </div>
          <hr className="reports-hr"></hr>
          { (group?.reports.length > 0) ?
          <DataGrid
          sx={{width: '100%'}}
          getRowId={(row) => row.ID}
          rows={group?.reports}
          columns={columnsReports}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          />
          : <Typography>
            No hay reportes asociados 
          </Typography>
        }
        </div>
      ) : (
        <div style={{ width: "100%", minHeight: '90vh', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <CircularProgress size='4vw' sx={{ margin: 'auto'}}/>
              </Box>
          </div>
      )}
    </>
  );
};

export default Group;

/**/
