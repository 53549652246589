import React from 'react';
import {  useDispatch } from 'react-redux';
import { Button,  Typography } from '@mui/material';
import { grantSuperUser } from '../../../services/pbiCracksApi';
import { setSnackBar } from '../../../store/Slice';

const GrantSuperUser = ({ handleCloseModal, mail, name}) => {
    const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
        await grantSuperUser( mail );
        changeSnackbar('success', `${name} es superuser!`)
        handleCloseModal();
      
    } catch (error) {
      changeSnackbar('error', 'Error al agregar grupo')
    }
  }

  const changeSnackbar = (estado, mensaje) => {

    const payload = {
      snackBar: {
        open: true,
        state: estado,
        msg: mensaje
      }
    }

    dispatch(setSnackBar(payload));
  };


  return (
    <>
    <div style={{display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'}}>

     <Typography sx={{ fontSize: 'xx-Large', fontWeight: '600', color: '#f98727', width: '100%', textAlign: 'center' }}>Estas seguro?</Typography>
     <p>Al aceptar {name} será superuser!</p>
     <div style={{width: '70%',
    display: 'flex',
    justifyContent: 'space-around'}}>

      <Button sx={{ marginTop: '3vh',     backgroundColor: '#777777', width: '8vw'}} variant="contained"  onClick={handleCloseModal}>Cancelar</Button>
      <Button sx={{ marginTop: '3vh', width: '8vw'}} variant="contained" onClick={handleSubmit}>Aceptar</Button>
     </div>
    </div>
    </>
  );
};

export default GrantSuperUser;

/*
*/
 