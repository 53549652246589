
import './Usuarios.css';

import React, { useState } from 'react';
import { Box,  Modal, Typography } from "@mui/material";
import { getUsers } from '../../services/pbiCracksApi';
import GrantSuperUser from './componentes/GrantSuperUser';
import '../groups/Groups.css';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const Usuarios = () => {
  const [users, setUsers] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [superUserInfo, setSuperuserInfo] = useState();
  const navigate = useNavigate();

  const handleOpenModal = (row) => {
    
    setSuperuserInfo(row)
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    obtenerUsers();
  };

  const obtenerUsers = async () => {
    try {
      const resp = await getUsers();
      setUsers(resp);
    } catch (error) {
      
    }
  };

  if (users === "") {
    obtenerUsers();
  }

  const ver = (params) =>{

    try {
      navigate(`/usuarios/${params.ID}`);
      
    } catch (error) {
      
    }
  }


  const columns = [

    {
      field: 'Name',
      headerName: 'Nombre',
      flex: 1
    },
    {
      field: 'Mail',
      headerName: 'Email',
      flex: 1
    },
    {
      field: "superuser",
      headerName: "Super User",
      type: "actions",
      renderCell: ({ row }) => (
        <>
        {row.Role === 'superuser'?
        <CheckCircleOutlineIcon sx={{color: 'green'}} />
        :
        <CancelOutlinedIcon />
        }
        
        </>
      ),
      sortable: false,
      maxWidth: 100,
      flex: 1,
    },
    
    {
      field: "editar",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => (
        
          <RemoveRedEyeOutlinedIcon className={'action-icon'}onClick={() => ver(row)}/>
        
      ),
      sortable: false,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          label="Convertir en superuser"
          onClick={(row) =>handleOpenModal(params.row)}
          showInMenu
        />,
      ]
    }
    
  ];




  return (
    <>
      <div>

        <div className="groups-header">
          <Typography sx={{ fontSize: 'xxx-Large', fontWeight: '600', width: '100%' }} color='white'>Usuarios</Typography>


        </div>



        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              p: 4,
            }}
          >
            <GrantSuperUser handleCloseModal={handleCloseModal} mail= {superUserInfo?.Mail} name= {superUserInfo?.Name}/>
          </Box>
        </Modal>

        <div style={{
          minHeight: '79vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{ width: '100%' }}>
            {(users !== []) ?

              <DataGrid
                getRowId={(row) => row.ID}
                rows={users}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 15, 20]}

              /> :
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', height: '10vh' }}>
                <CircularProgress size='6vw' />
              </Box>
            }


          </div>
        </div>


      </div>
    </>
  );

}

export default Usuarios;
