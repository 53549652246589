const MICROSOFT_APP_ID = 'e577d006-030b-4e19-8373-476d69a94977'
const REDIRECT_URI = 'http://localhost:3000/'
const TENANT_LOGIN_URI = 'https://login.microsoftonline.com/levelup.com.uy'

export const config = {
    appid: process.env.MICROSOFT_APP_ID || MICROSOFT_APP_ID,
    redirectUri: process.env.REDIRECT_URI || REDIRECT_URI,
    scopes: [
        'user.read'
    ],
    authority: process.env.TENANT_LOGIN_URI || TENANT_LOGIN_URI
}
