import React, { useState} from "react";
import { Typography, IconButton, Box, Modal } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import UserAddGr from "./UserAddGr";
import UserAddRp from "./UserAddRp";
import { getUserId, getUserMe } from "../../../services/pbiCracksApi";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../../../components/AlertDialog";
import GroupAdminModal from "../../groups/components/GroupAdminModal";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from "react-router-dom";


const User = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  const [openModalUsr, setOpenModalUsr] = useState(false);
  const [openModalRpt, setOpenModalRpt] = useState(false);
  const { id } = useParams();
  
  const [userData, setDataResp] = useState();
  const [grantData, setGrantData] = useState();
  const [userDataAux, setDataRespAux] = useState();
  const [update, setUpdate] = useState({update: false});
  const [groupData, setGroupData] = useState();
  const [openModalAdmin, setOpenModalAdmin] = useState(false);
  const navigate = useNavigate();
  const obtenerUsuario =  async ()   => {
    

    //try {
      let resp = userData;
      


      if (id) {
        resp =  await getUserId(id);
       
      }
      else {
        resp = await getUserMe();
       

      }
      
      
      return resp 
    
  
  };

  const actualiza = async () => {

    const resp = await obtenerUsuario();
    setDataResp(resp);
    setDataRespAux(resp);
  }
    

 
    if (!userData) {
      actualiza()
      }
    if(update === true){

      actualiza();
      setUpdate( false);
    }

  const verGroup = (id) =>{
    navigate(`/grupos/${id}`)
  }

  const verReport = (id) =>{
    navigate(`/reportes/${id}`)
  }

  const handleOpenModaAdmin = (row) => {
    setGroupData(row)

    let aux = userData;

    aux.readonly = row.readonly;

    setGrantData(aux)

    setOpenModalAdmin(true);

  };

  




  
  const handleOpenModalUsr = () => {
    setOpenModalUsr(true);
  };

  const handleOpenModalRpt = () => {
    setOpenModalRpt(true);
  };

  const handleCloseModal = async () => {
    
    setUpdate(true);
    setOpenModalAdmin(false);
    setOpenModalUsr(false);
    setOpenModalRpt(false);

  };

  const columnsReports = [
    {
      field: "Name",
      headerName: "Nombre",

      flex: 4
    },
    
    
    {
      field: "editar",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => (
        
          <RemoveRedEyeOutlinedIcon className={'action-icon'}onClick={() => verReport(row.ReportID)}/>
        
      ),
      sortable: false,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "eliminar",
      headerName: "Eliminar",
      type: "actions",
      renderCell: ({ row }) => (
        <AlertDialog disabled={!(user?.Role === 'superuser')} setUpdate={setUpdate} option='remove-user-report' Mail={userDataAux.Mail} reports={row} />
      ),
      sortable: false,
      flex: 1
    },
    
    
    {
      field: 'actions',
      type: 'actions',
      getActions: ({row}) => [
        
        
      
        
      ]
    }

  ];

  const columnsGroups = [
    {
      field: "Name",
      headerName: "Nombre",

      flex: 3,
    },
    
    {
      field: "administrador",
      headerName: "Administrador",
      type: "actions",
      renderCell: ({row}) => (
        <>{ row.readonly === 1 ?

          <CancelOutlinedIcon />
        :
        <CheckCircleOutlineIcon sx={{color: 'green'}} />
        }
          </>
      ),
      sortable: false,
      flex: 1
    },
    {
      field: "editar",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => (
        
          <RemoveRedEyeOutlinedIcon className={'action-icon'}onClick={() => verGroup(row.ID)}/>
        
      ),
      sortable: false,
      maxWidth: 100,
      flex: 1,
    },
    
    
    {
      field: "eliminar",
      headerName: "Eliminar",
      type: "actions",
      
        renderCell: ({ row }) => (
          <AlertDialog disabled={!(user?.Role === 'superuser')}   setUpdate={setUpdate} option='remove-user-group' Mail={userDataAux.Mail} groups={row} />
        ),
      
      sortable: false,
      flex: 1
    },
    
    
    {
      field: 'actions',
      type: 'actions',
      getActions: ({row}) => [
        
        <GridActionsCellItem
        label={row.readonly === 0?"Quitar permisos administrador": "Dar permisos administrador" }
         onClick={() =>handleOpenModaAdmin(row)}
        showInMenu
        />
      
      
        
      ]
    }
  ];

  return (
    <>
      <Modal open={openModalRpt} onClose={() =>handleCloseModal()}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <UserAddRp id={userDataAux} setUpdate={setUpdate} handleCloseModal={() =>handleCloseModal()} />
        </Box>
      </Modal>

      <Modal open={openModalUsr} onClose={() =>handleCloseModal()}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <UserAddGr id={userDataAux} setUpdate={setUpdate} handleCloseModal={() =>handleCloseModal()} />
        </Box>
      </Modal>
      <Modal open={openModalAdmin} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <GroupAdminModal setUpdate={setUpdate} id={groupData?.ID} adminData={grantData} handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>
      {userDataAux? (
        <div style={{display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center'}}>
          

          

          <div className="groups-header">
          <Typography
            sx={{
              fontSize: "xxx-Large",
              fontWeight: "600",
              color: "white",
              width: "50%",
              textAlign: "left",
            }}
          >
            {userDataAux?.Name}
          </Typography>
          <div style={{display: 'flex', width:'20%', flexWrap: 'wrap'}}>

          <Typography
            sx={{
              fontSize: "x-Large",
              fontWeight: "600",
              color: "white",
              width: "100%",
              textAlign: "left",
            }}
            >
            Mail: {userDataAux?.Mail}
          </Typography>

          <Typography
            sx={{
              fontSize: "x-Large",
              fontWeight: "600",
              color: "white",
              width: "100%",
              textAlign: "left",
            }}
            >
            Role: {userDataAux?.Role}
          </Typography>
          </div>
        </div>
          
           
          <div className="group-header">
            <Typography
              sx={{
                fontSize: "xx-Large",
                fontWeight: "600",
                color: "#f98727",
                width: "100%",
              }}
            >
              Reportes
            </Typography>
            <div style={{ margin: "auto" }}>
              <IconButton disabled={!(user?.Role === 'superuser')} onClick={() => handleOpenModalRpt()}>
                <AddCircleOutlineIcon
                  sx={{ fontSize: 40 }}
                  style={{ color: "#f98727" }}
                />
              </IconButton>
            </div>
          </div>

          <hr className="reports-hr"></hr>
          {userDataAux?.reports.length > 0 ?
            <DataGrid
              sx={{ width: '100%' }}
              getRowId={(row) => row.ID}
              rows={userDataAux?.reports}
              columns={columnsReports}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
            : <Typography>
              No hay reportes individuales asociados
            </Typography>
          }
          <div className="group-header">
            <Typography
              sx={{
                fontSize: "xx-Large",
                fontWeight: "600",
                color: "#f98727",
                width: "100%",
              }}
            >
              Grupos
            </Typography>
            <div style={{ margin: "auto" }}>
              <IconButton disabled={!(user?.Role === 'superuser')} onClick={() => handleOpenModalUsr()}>
                <AddCircleOutlineIcon
                  sx={{ fontSize: 40 }}
                  style={{ color: "#f98727" }}
                />
              </IconButton>
            </div>
          </div>
          <hr className="reports-hr"></hr>
          {(userDataAux?.groups?.length > 0) ?
            <DataGrid
              sx={{ width: '100%' }}
              getRowId={(row) => row.ID}
              rows={userDataAux?.groups}
              columns={columnsGroups}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
            : <Typography>
              No hay grupos asociados
            </Typography>
          }
        </div>
      ) : (
        <div style={{ width: "100%", minHeight: '95vh', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <CircularProgress size='4vw' sx={{ margin: 'auto' }} />
          </Box>
        </div>
      )}
    </>
  );
};



export default User


