import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  error: false,
  isLogged: null,
  addingReport: false,
  user: {},
  userView: {},
  lateralMenuOpen: true,
  snackBar: {open: false,
                 state: '',
                 msg: ''},
};

export const Slice = createSlice({
  name: 'Slice',
  initialState,
  reducers: {
    setError: (state,action) =>{
     
      
        
      state.error  = action.payload.error;
      
  },
    setLoginUser: (state,action) =>{
      const { payload } = action;
      
        
        state.user  = payload;
        
        state.isLogged = true;
    },
    setUserView: (state,action) =>{
     
      
        
        state.userView  = action.payload.userView;
        
    },
    setLogoutUser: (state) => {
      state.isLogged = false;
      state.user = []
      localStorage.clear();
    },
    setaddinReport: (state, action) => {
      
      state.addingReport = action.payload.addingReport;
    },
    setlateralMenuOpen: (state, action) => {
      
      state.lateralMenuOpen = action.payload.lateralMenuOpen;
    },
    setSnackBar: (state, action) => {
      
      state.snackBar = action.payload.snackBar;
    },
    
  },
});

export const { setError,setUserView, setLoginUser, setLogoutUser,setaddinReport, setlateralMenuOpen, setSnackBar } =
  Slice.actions;
export default Slice.reducer;
