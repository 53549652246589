import * as React from "react";
import { IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import "./reportCard.css";
import CardActions from "@mui/material/CardActions";
import { useNavigate } from 'react-router-dom';
import GradeIcon from '@mui/icons-material/Grade';
import { postFavorite } from "../../../services/pbiCracksApi";
import AlertDialog from "../../../components/AlertDialog";

export default function ReportCard({ report, setUpdate }) {
  const navigate = useNavigate();


  const user = JSON.parse(localStorage.getItem('user'));
  const onClick = () => {
    navigate(`/reportes/${report.ReportID
      }`);
  };

  React.useEffect(() => {
 

   
    

  }, [report]);


  const changeFavorite = async (data) => {

    try {
      let f = data;
      

      if (f === 1) {
        f = 0;
      }
      else {    
        f = 1;
      }

       await postFavorite(report.ReportID, f)
      setUpdate(true);
      

    } catch (error) {

    }


  }
  return (
    <Card className="card">

      <CardActionArea onClick={() => onClick()}>
        

        <div className="reporte-svg">
        </div>
        
        <CardContent >
          <div style={{ height: '6rem' }}>

            <Typography gutterBottom variant="h5" component="div">
              {report.Name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {report.description || "No hay descripcion."}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: 'space-around' }}>
        <AlertDialog setUpdate={setUpdate}disabled={!(user?.Role === 'superuser')} option='delete-report' ReportID={report.ReportID}/>
        
        {report.favorite === 1 ?
          <IconButton onClick={() => changeFavorite(report.favorite)} >

            <GradeIcon
              sx={{ fontSize: 30, color: '#f98727' }}

            />

          </IconButton>
          :

          <IconButton onClick={() => changeFavorite(report.favorite)}>

            <GradeIcon
              sx={{ fontSize: 30 }}

            />

          </IconButton>
        }
      </CardActions>
    </Card>
  );
}
