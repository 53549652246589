import React, { useEffect, useState } from 'react';
import {  useDispatch } from 'react-redux';
import { Button, TextField, Typography } from '@mui/material';
import Tags from '../../../shared/AutoCompleteChips';
import { getUsers, createGroup } from '../../../services/pbiCracksApi';
import { useMsal } from '@azure/msal-react';
import { setSnackBar } from '../../../store/Slice';

const GroupForm = ({ handleCloseModal, setUpdate }) => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();
  const [tagsValue, setTagsValue] = useState([]);
  const [users, setUsers] = useState([]);
  const [groupName, setGroupName] = useState(''); // Nuevo estado para el nombre del grupo
  const [error, setError] = useState({groupName: false}); 
  const handleTagsChange = (newValue) => {
    setTagsValue(newValue);
  };
  const dispatch = useDispatch();
  const handleNameChange = (event) => {
    setGroupName(event.target.value); // Actualiza el estado del nombre del grupo
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await getUsers(currentAccount.accessToken);
        setUsers(fetchedUsers);
      } catch (error) {
       
      }
    };
    fetchUsers(); 
  }, [currentAccount.accessToken]);

  const handleCreateGroup = async () => {
    if(validate()){
    try {
      
        await createGroup(  groupName,  tagsValue );
        setUpdate(true);
        changeSnackbar('success', 'Agregado correctamente')
        handleCloseModal();
      
    } catch (error) {
      changeSnackbar('error', 'Error al agregar grupo')
    }
  }
  };

  const changeSnackbar = (estado, mensaje) => {

    const payload = {
      snackBar: {
        open: true,
        state: estado,
        msg: mensaje
      }
    }

    dispatch(setSnackBar(payload));
  };

  const validate = () => {
   const e = {}
    if(groupName === ''){
      e.groupName = true
      changeSnackbar('error', 'Complete campo obligatorio')
      setError(e);
      return false
    }
    setError(e);
      return true

  }


  return (
    <>
    <div style={{width:'30vw'}}>

     <Typography sx={{ fontSize: 'xx-Large', fontWeight: '600', color: '#f98727', width: '100%', textAlign: 'center' }}>Crear grupo</Typography>
      <TextField id="name" error={error.groupName} label="Nombre" variant="standard" size="small" value={groupName} onChange={handleNameChange} />
      <Tags value={tagsValue} onChange={handleTagsChange} users={users} /> 
      <Button sx={{ marginTop: '3vh'}} variant="contained" onClick={handleCreateGroup}>Crear grupo</Button>
    </div>
    </>
  );
};

export default GroupForm;

/*
*/
 
