import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Groups from "../pages/groups/Groups";
import Reportes from "../pages/reports/Reportes";
import Usuarios from "../pages/usuario/Usuarios";
import AssessmentIcon from "@mui/icons-material/Assessment";
import User from "../pages/usuario/componentes/User";
import GroupsIcon from "@mui/icons-material/Groups";
import LayersIcon from '@mui/icons-material/Layers';
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector} from "react-redux";
import {
  HashRouter, 
  Route,
  Routes,
  Link
} from "react-router-dom";
import Reporte from "../pages/reports/components/Reporte";
import Group from "../pages/groups/components/Group";

function TabPanel(props) {

  const { children, value, index, ...other } = props;






  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}


export default function VerticalTabs() {
  const lateralMenuOpen = useSelector((state) => state.Slice.lateralMenuOpen);
  const [value, setValue] = React.useState(0);
  const user2 = useSelector((state) => state.Slice.user);
  const [user, setUser] = React.useState(user2);
  //const user = JSON.parse();



  React.useEffect(() => {
    if(!user?.Role){

      setUser(JSON.parse(localStorage.getItem('user')))
    }
   
    
    const aux = window.location.pathname;
    
    if(aux.includes('grupos')){
      setValue(1);
    }
    if(aux.includes('reportes')){
      setValue(0);
    }
    if(aux.includes('usuarios')){
      setValue(2);
    }
    if(aux.includes('perfil')){
      setValue(3);
    }
    
    }
  , [user, user2]);

  

  const handleChange = (event, newValue) => {
    setValue(newValue, user);
  };


  


  return (
    <>
      {(user?.Role|| user2?.Role) ?

        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: "100%",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={(e, f) => handleChange(e, f)}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              overflow: "unset",
              backgroundColor: "#eeeeee",
            }}
            className="Menu-header"
            aria-expanded={lateralMenuOpen}
          >
            <Tooltip
              title="Reportes"
              placement="right"
              TransitionComponent={Zoom}
            >
              <Tab
                component={Link}
                to="/reportes"
                sx={{
                  minWidth: "0px",
                  paddingLeft: "15px",
                  paddingRight: "10px",
                }}
                icon={<AssessmentIcon sx={{ fontSize: 30 }} />}
                label=""
                {...a11yProps(0)}
              />
            </Tooltip>
            <Tooltip title="Grupos" placement="right" TransitionComponent={Zoom}>
              <Tab
                component={Link}
                to="/grupos"
                sx={{
                  minWidth: "0px",
                  paddingLeft: "15px",
                  paddingRight: "10px",
                }}
                icon={<LayersIcon sx={{ fontSize: 30 }} />}
                label=""
                {...a11yProps(1)}
              />
            </Tooltip>
            {user?.Role === 'superuser' &&
              <Tooltip
                title="Usuarios"
                placement="right"
                TransitionComponent={Zoom}
              >
                <Tab
                  component={Link}
                  to="/usuarios"
                  sx={{
                    minWidth: "0px",
                    paddingLeft: "15px",
                    paddingRight: "10px",
                  }}
                  icon={<GroupsIcon sx={{ fontSize: 30 }} />}
                  label=""
                  {...a11yProps(2)}
                />
              </Tooltip>
            }
          </Tabs>
          <Routes>
            <Route
              path="/"
              element={
                <>
                
                <TabPanel value={0} index={0} class="tab-panel">
                  <Reportes />
                </TabPanel>
                </>
              }
            ></Route>
            <Route
              path="/reportes"

              element={
                <>
                
                <TabPanel value={0} index={0} class="tab-panel">
                  <Reportes />
                </TabPanel>
              
              </>
              }
            ></Route>
            <Route
              path="/reportes/:id"
              element={
                <>
                
                <TabPanel value={0} index={0} class="tab-panel">
                  <Reporte />
                </TabPanel>
              
              </>}
            ></Route>
            <Route
              path="/grupos"

              element={
                <>
                
                <TabPanel value={1} index={1} class="tab-panel">
                  <Groups />
                </TabPanel>
                </>
              }

            ></Route>
            <Route
              path="/grupos/:id"
              element={
                <>
                
                <TabPanel value={1} index={1} class="tab-panel">
                  <Group />
                </TabPanel>
                </>
              }
            ></Route>
            <Route
              path="/perfil"
              element={
                <>
                <TabPanel value={2} index={2} class="tab-panel">

                  <User />
                </TabPanel>
                </>
              }
            ></Route>
            {user?.Role === 'superuser' &&
              <>
              <Route
                path="/usuarios"

                element={
                  <>
                
                  <TabPanel value={2} index={2} class="tab-panel">
                    <Usuarios />
                  </TabPanel>
                  </>
                }
              ></Route>
              <Route
              path="/usuarios/:id"
              element={
                <>
                <TabPanel value={2} index={2} class="tab-panel">

                  <User />
                </TabPanel>
                </>
              }
            ></Route>
            </>
              }
            <Route element={
              <>
              
              <TabPanel value={0} index={0} class="tab-panel">
                <Reportes />
              </TabPanel>
              </>
            } path="*" />
          </Routes>
        </Box>
        :
        <div style={{ width: "100%", minHeight: '96vh', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <CircularProgress size='4vw' sx={{ margin: 'auto' }} />
          </Box>
        </div>
      }

    </>
  );
}
